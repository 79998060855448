function Footer(props) {
    return (
        <div className="px-6 text-gray-200 md:px-0 mb-6 lg:mb-[10vh] flex flex-col gap-4 max-w-sm xl:max-w-md md:text-center md:mx-auto font-light justify-between">

            <div className="">
                <p className="text-gray-300">Email me with <a onClick={()=>{cabinEvent(`Clicked footer email link`)}} href="mailto:hello@jaycollett.co" className="text-white inline-block underline underline-offset-4 font-normal hover:text-orange-500 transition-colors md:font-light md:text-lg mb-6 text-base" target="_blank" rel="noopener noreferrer">hello@jaycollett.co</a>.</p>
            </div>

            <p className="text-sm xl:text-base tracking-wide text-gray-300 [&>a]:underline [&>a]:transition-colors hover:[&>a]:text-orange-500">{/*This website is hosted <a onClick={()=>{cabinEvent(`Clicked green hosting link`)}} href="https://www.thegreenwebfoundation.org/green-web-check/?url=jaycollett.co" target="_blank" rel="noopener noreferrer">green</a> and */} This website sustainably tracks usage without invading your privacy using <a href="https://www.withcabin.com/" onClick={()=>{cabinEvent(`Clicked Cabin link`)}} target="_blank" rel="noopener noreferrer">Cabin</a>. Each visit creates just <a onClick={()=>{cabinEvent(`Clicked website carbon link`)}} href="https://www.websitecarbon.com/website/jaycollett-co/" target="_blank" rel="noopener noreferrer">0.03g of CO2</a>.</p>
            <p className="text-sm xl:text-base text-gray-300">&copy; {new Date().getFullYear()}</p>
        </div>
    )
}

export default Footer