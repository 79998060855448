import AnchorLink from "react-anchor-link-smooth-scroll"
import { ArrowDownIcon, EnvelopeIcon } from '@heroicons/react/24/solid'
import removeWidows from "@/utils/helpers/removeWidows"

function Splash(props) {
    
    const {
        introduction
    } = props || {}
    
    const {
        body,
        title
    } = introduction || {}

    return (
        <div className="min-h-screen p-6 md:p-8 lg:p-10 xl:p-14 2xl:p-16 w-full relative z-20 text-white flex flex-col justify-between">

            <div className="lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl">
                <div className="w-content">
                    <h1 className="leading-tight tracking-wide text-orange-500 mb-2 font-light text-2xl sm:text-3xl md:text-4xl lg:text-[4vw] xl:text-[3.5vw]">
                        {removeWidows(title)}
                    </h1>
                    <p className="font-light tracking-wide text-2xl sm:text-3xl md:text-4xl lg:text-[4vw] xl:text-[3.5vw] leading-tight">{body}</p>
                    {/* <p className="mt-6 lg:mt-8 font-light tracking-wide text-lg sm:text-xl md:text-2xl xl:text-3xl leading-tight">🇮🇩 Currently remote working in Bali</p> */}
                </div>
            </div>

            <div className="mt-10 pb-4 md:pb-0 flex flex-col-reverse gap-4 md:flex-row lg:gap-4 2xl:gap-6">
                <div>
                    <AnchorLink onClick={()=>{cabinEvent(`Clicked 'see my work'`)}} offset='0' href="#projects" className="w-52 sm:w-60 md:w-auto text-left overflow-hidden h-12 rounded-full border-orange-500 border xl:h-14 2xl:h-16 px-6 2xl:px-7 inline-flex items-center transition hover:bg-white hover:border-white duration-500 hover:text-black text-left relative">
                        <>
                            <span className="z-20 tracking-wide relative inline-block font-normal xl:font-light pr-10 text-sm sm:text-base xl:text-lg 2xl:pr-20">See my work</span>
                            <span className="absolute z-20 top-1/2 right-4 xl:right-6 -translate-y-1/2"><ArrowDownIcon className="w-4 h-4 2xl:w-5 2xl:h-5"/></span>
                        </>
                    </AnchorLink>
                </div>
                <div>
                    <a onClick={()=>{cabinEvent(`Clicked email call to action`)}} href="mailto:hello@jaycollett.co" target="_blank" rel="noopener noreferrer" className="w-52 sm:w-60 md:w-auto text-left overflow-hidden h-12 rounded-full border-orange-500 border xl:h-14 2xl:h-16 px-6 2xl:px-7 inline-flex items-center transition hover:bg-white hover:border-white duration-500 hover:text-black text-left relative">
                        <span className="z-20 tracking-wide relative inline-block font-normal xl:font-light pr-10 text-sm sm:text-base xl:text-lg 2xl:pr-20">hello@jaycollett.co</span>
                        <span className="absolute z-20 top-1/2 right-4 xl:right-6 -translate-y-1/2"><EnvelopeIcon className="w-4 h-4 2xl:w-5 2xl:h-5"/></span>
                    </a>
                </div>
            </div>


        </div>
    )
}

export default Splash