function sort(type, projects) {

    const dateAsc = (a, b) => Number(a.date) - Number(b.date)

    const dateDes = (a, b) => Number(b.date) - Number(a.date)

    let sortedProjects = projects
    
    switch (type) {
        case 'dateAsc':
            sortedProjects = projects.sort(dateAsc)
            break;
            
        default:
            sortedProjects = projects.sort(dateDes)
            break;
    }

    return sortedProjects
}

export default sort