import Footer from '@/components/Footer';
import Projects from '@/components/Projects';
import Splash from '@/components/Splash';
import client from '@/utils/sanity/client'

export default function Home(props) {
    
    const {
        data
    } = props || {}
    
    const {
        projects,
        introduction
    } = data || {}

    return (
        <div className="relative">

            <Splash introduction={introduction} />
            <Projects projects={projects} />
            <Footer />

        </div>
    )
}

export async function getStaticProps() {
    const data = await client.fetch(`
        {
            'projects': 
                *[_type == "project" && completed < now()] | order(completed desc){
                    completed,
                    title,
                    url,
                    partner->{
                        name,
                        shortName,
                        url
                    },
                    tags[]->{
                        title
                    }
                },
            'introduction': *[_type == 'introduction'][0]
        }
    `)
    return {
        props: {
            data
        },
        revalidate: 10
    }
}