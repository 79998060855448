import cabinEvent from '@/utils/helpers/cabinEvent'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

function ProjectItem(props) {

    const {
        project
    } = props || {}

    const {
        completed,
        partner,
        url,
        tags,
        title,
    } = project || {}

    return (
        <li className="flex-auto">
            <div className="flex py-6 md:py-10 lg:py-8 relative group text-white flex-col justify-center w-full h-full xl:hover:pb-12 transition-all">
                <a onClick={()=>{cabinEvent(`Viewed ${title}`)}} href={url} target="_blank" rel="noopener noreferrer" className="transition-colors absolute top-0 left-0 w-full h-full z-10" aria-label={`Visit ${title}`} />

                <div className="grid grid-cols-6 md:grid-cols-12 gap-y-4 md:gapy-y-4">

                    <div className="col-span-6 md:col-span-9 xl:col-span-10">
                        <div className={`text-xs tracking-wide mb-1 lg:mb-2 sm:text-sm xl:text-base text-gray-300 font-light`}>
                            <span className="">{new Date(completed).getFullYear()}</span>
                            {partner && (
                                <span><span className="inline-block px-2 lg:px-4">•</span>Delivered with <a onClick={()=>{cabinEvent(`Viewed ${partner.shortName}`)}} className="p-2 -mt-2 -ml-2 relative z-20  inline-block cursor-pointer pointer-events-auto transition-colors underline underline-offset-4 hover:text-orange-500" href={partner.url} target="_blank" rel="noopener noreferrer">
                                    {partner.shortName ? (
                                        <span className="inline md:hidden">{partner.shortName}</span>
                                    ) : ''}
                                    <span className={`${partner.shortName ? 'hidden md:inline' : ''}`}>{partner.name}</span>
                                </a></span>
                            )}
                        </div>
                        <h2 className="transition-colors tracking-wide decoration-1 underline-offset-8 inline-block box-decoration-clone whitespace-pre-wrap font-body pointer-events-none relative z-20 leading-none text-[8vw] md:text-[7vw] lg:text-[4vw] font-light group-hover:text-orange-500">{title}&nbsp;<span aria-hidden="true" className="inline text-black pointer-events-none relative select-none">.<ArrowRightIcon className="absolute top-1/2 -translate-y-5/12 left-0 hidden md:inline opacity-0 text-white -translate-x-4 group-hover:translate-x-0 transition group-hover:opacity-100 w-6 h-6 lg:w-8 lg:h-8 2xl:w-10 2xl:h-10"/></span></h2>
                    </div>

                    <div className="col-span-6 md:col-span-3 xl:col-span-2">
                        <ul className="font-light tracking-wide text-gray-300 relative z-20 leading-none text-xs transition-colors pointer-events-none sm:text-sm flex flex-wrap flex-row gap-x-3 gap-y-2 xl:text-base md:pb-1 md:flex-col md:items-end md:justify-end h-full">
                            {tags.map((tag, n) => (
                                <li key={n} className="inline-block">{tag.title}</li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
        </li>
    )
}

export default ProjectItem