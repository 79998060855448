import { useState } from "react"
import ProjectItem from '@/components/ProjectItem'
import OrderToggle from "@/components/OrderToggle";
import sortProjects from '@/utils/helpers/sortProjects';

function Projects(props) {
    const [order, setOrder] = useState('dateDes')

    const {
        projects
    } = props || {}

    const orderedProjects = sortProjects(order, projects)

    return (
        <div id="projects" className="px-6 py-10 md:p-8 lg:p-10 xl:p-14 2xl:p-16">

            {/* <div className="flex sm:justify-end mb-6">
                <OrderToggle
                    order={order}
                    setOrder={setOrder}
                />
            </div> */}

            <ul className="flex flex-col border-y border-gray-500 divide-y divide-gray-500">
                {orderedProjects.map((project, n) => (
                    <ProjectItem
                        key={n}
                        project={project}
                    />
                ))}
            </ul>
        </div>
    )
}

export default Projects